import NextImage from 'next/image'

const noCache = src => {
  if (!src) return true
  if (src.slice(0, 4) === 'data') return true
  if (src.includes('firebase')) return true
  return false
}

export default function BaseImage({ alt, sizes, src, type, className, style, onClick, noLoading, onLoad, downloadable, priority }) {
  alt ||= ''
  type ||= 'cover'
  style ||= {}
  onClick ||= () => {}
  sizes ||= '100vw'

  const { state, mutate } = useStatic({
    isFinish: false,
    loading: !noLoading,
    noCache: noCache(src),
    rawImage: false
  })

  useEffect(() => () => {
    state.isFinish = true
  }, [])

  state.finish ||= ({ width, height }) => {
    if (state.isFinish) return
    state.isFinish = true
    if (onLoad) onLoad({ width, height })
    if (!state.loading) return
    state.loading = false
    mutate()
  }

  const renderImage = () => {
    const renderNextImage = skipOnload => (
      <NextImage
        layout='fill'
        alt={ alt }
        src={ src }
        sizes={ sizes }
        objectFit={ type }
        onLoadingComplete={ ({ naturalWidth, naturalHeight }) => {
          if (skipOnload) return
          state.finish({ width: naturalWidth, height: naturalHeight })
        } }
        objectPosition='center center'
        priority={ priority }
      />
    )
    if (!src) return
    if (!state.noCache) return renderNextImage()

    if (!state.rawImage) {
      state.rawImage = true
      const image = new Image()
      image.onload = () => {
        const { width, height } = image
        state.finish({ width, height })
      }
      image.src = src
    }

    if (downloadable) return renderNextImage(true)
    // eslint-disable-next-line @next/next/no-img-element
    return (
      <div className='absolute inset-0'
        style={
          {
            backgroundImage: `url(${src})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: type
          }
        }
      />
    )
  }

  return (
    <div
      className={ cn(className, 'relative overflow-hidden') }
      onClick={ onClick }
      style={ style }
    >
      <div className={ cn('bg-gray-200/90 absolute inset-0 flex justify-center -z-1', { hidden: !state.loading }) }>
      </div>
      { renderImage() }
    </div>
  )
}
